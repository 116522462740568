<template>
  <div class="import-dialog-component">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="dialogCloseHandler"
      width="600px"
      custom-class="import-dialog-component"
      :title="dataset.datas && dataset.datas.title"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div class="template-download card-container">
        <span class="left-part">
          <img src="@/assets/images/excel_icon.png" alt="" />
          <span> 下载导入模板，根据模板提示完善内容 </span>
        </span>
        <r-button type="default" @click="downloadTemplate">下载模板</r-button>
      </div>
      <div class="upload-card card-container">
        <el-upload
          ref="upload"
          accept=".xls, .xlsx"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="handleChange"
          :on-success="successHandler"
          :on-error="errorHandler"
          :before-upload="beforeUpload"
          drag
          :action="uploadPathBuild()"
          multiple
        >
          <img
            src="@/assets/images/import_folder_icon.png"
            style="margin-bottom: 8px"
            alt=""
          />
          <div class="el-upload__text">
            将文件拖到此处，或<em style="color: #2a61ff">点击选择文件</em>
          </div>
        </el-upload>
      </div>
      <div
        v-for="item in fileList"
        :key="item.uid"
        class="file-list card-container"
      >
        <div class="top-line">
          <span class="name-span">
            <img src="@/assets/images/excel_icon.png" alt="" />
            <div class="size-part">
              <div class="name-line">
                {{ item.name }}
              </div>
              <div class="size-line">
                {{ sizeRender(item.size) }}
              </div>
            </div>
          </span>
          <i class="el-icon-close" @click="deleteHandler(item)"></i>
        </div>
        <div class="progress-line">
          <div class="outer">
            <div
              class="inner"
              :style="{
                width: `${progressRate}%`,
              }"
            ></div>
          </div>
          <div class="rate-part">{{ progressRate }}%</div>
        </div>
      </div>
      <div class="button-line">
        <r-button type="cancel" @click="dialogCloseHandler">取消</r-button>
        <r-button
          plain
          :loading="uploadLoading"
          @click="submitUpload"
          :disabled="!fileList.length || progressRate !== 100"
          >导入</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downTemplate } from "@/utils/download";

export default {
  name: "import-dialog-component",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
          /**
           * title: 弹框标题
           * excelType: 导入类型
           * path: 导入path
           * rootPath: 导入时指定为rootPath
           * taskName：导入任务名称
           */
          datas: {
            title: "文件导入",
            excelType: null,
            path: null,
            taskName: null,
          },
        };
      },
    },
  },
  data() {
    return {
      fileList: [],
      progressRate: 0,
      uploadLoading: false,
    };
  },
  methods: {
    beforeUpload(file) {
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 <= 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        this.uploadLoading = false;
        return false;
      }
    },
    uploadPathBuild() {
      if (this.dataset.datas) {
        const { rootPath, excelType, taskName } = this.dataset.datas;
        return `${rootPath}/param/excel/upload?ulType=ExcelImport&excelType=${excelType}&taskType=${taskName}`;
      }
    },
    successHandler() {
      this.uploadLoading = false;
      this.resetDatas();
      this.$emit("success");
    },
    errorHandler() {
      this.uploadLoading = false;
      this.resetDatas();
      this.$emit("error");
    },
    deleteHandler({ uid }) {
      this.fileList = this.fileList.filter((item) => item.uid !== uid);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
      this.progressAnimation();
    },
    progressAnimation() {
      this.progressRate = 0;
      setTimeout(() => {
        this.progressRate = 100;
      }, 200);
    },
    dialogCloseHandler(flag) {
      this.resetDatas();
      this.$emit("close", flag);
    },
    resetDatas() {
      this.fileList = [];
      this.progressRate = 0;
    },
    downloadTemplate() {
      // downTemplate('ruge.property', envInfo.bgApp.dorisPath);
      const { excelType, path } = this.dataset.datas;
      downTemplate(excelType, path);
    },
    submitUpload() {
      this.uploadLoading = true;
      this.$refs.upload.submit();
    },
    sizeRender(size) {
      if (size > 1024 * 1024) {
        return `${(size / 1024 / 2014).toFixed()}MB`;
      }
      return `${(size / 1024).toFixed()}KB`;
    },
  },
};
</script>

<style lang="less">
.import-dialog-component {
  .card-container {
    background: #fcfdff;
    border-radius: 10px;
    border: 1px solid #e8ebf3;
    padding: 18px;
  }
  .card-container + .card-container {
    margin-top: 16px;
  }
  .template-download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-part {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #5d687c;
      img {
        margin-right: 8px;
      }
    }
  }
  .el-upload {
    width: 100%;
  }
  .el-upload-dragger {
    width: 100%;
    background: #fcfdff;
    border: none;
    height: 148px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .file-list {
    .top-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-span {
        display: flex;
        align-items: center;
        .size-part {
          display: flex;
          flex-direction: column;
          margin-left: 8px;
          .name-line {
            color: #252d3d;
          }
          .size-line {
            color: #a7b4c7;
            margin-top: 4px;
          }
        }
      }
      .el-icon-close {
        position: relative;
        top: -7px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .progress-line {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .outer {
        flex: 1;
        background: #e6eef5;
        border-radius: 10px;
        position: relative;
        height: 8px;
        .inner {
          transition: all 0.7s ease-in-out;
          background: #2a61ff;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
        }
      }
      .rate-part {
        margin-left: 12px;
      }
    }
  }
  .button-line {
    text-align: right;
    margin: 30px 0 40px;
  }
}
</style>