<template>
  <div class="app-container device-list-comp">
    <div class="filter-line">
      <span class="left-part">
        <el-button type="primary" icon="el-icon-plus" @click="createDevice">
          添加设备</el-button
        >
        <el-dropdown
          @command="enableHandleCommand"
          placement="bottom-start"
          @visible-change="
            (value) => {
              startDrop = value;
            }
          "
        >
          <span class="el-dropdown-link dropdown-container">
            <i class="el-icon-lock"></i>
            启用
            <i v-if="startDrop" class="el-icon-arrow-up el-icon--right"></i>
            <i v-else class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in enableOptions"
              :key="index"
              :command="item.key"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          @command="deleteHandleCommand"
          placement="bottom-start"
          @visible-change="
            (value) => {
              deleteDrop = value;
            }
          "
        >
          <span class="el-dropdown-link dropdown-container">
            <i class="el-icon-delete"></i>
            删除
            <i v-if="deleteDrop" class="el-icon-arrow-up el-icon--right"></i>
            <i v-else class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in deleteOptions"
              :key="index"
              :command="item.deleteValue"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          @command="tagHandleCommand"
          placement="bottom-start"
          @visible-change="
            (value) => {
              tagDrop = value;
            }
          "
        >
          <span class="el-dropdown-link dropdown-container">
            <i class="el-icon-collection-tag"></i>
            标签
            <i v-if="tagDrop" class="el-icon-arrow-up el-icon--right"></i>
            <i v-else class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in tagOptions"
              :key="index"
              :command="item.tagValue"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
          @command="importHandleCommand"
          placement="bottom-start"
          @visible-change="
            (value) => {
              importDrop = value;
            }
          "
        >
          <span class="el-dropdown-link dropdown-container">
            <i class="el-icon-upload2"></i>
            导入
            <i v-if="importDrop" class="el-icon-arrow-up el-icon--right"></i>
            <i v-else class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in importOptions"
              :key="index"
              :command="item.key"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          @click="exportHandler"
          icon="el-icon-download"
          class="select-container"
          >导出</el-button
        >
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:productKeySearchSlot>
          <el-select
            v-model="dataset.searchLineConfig.productKey.value"
            multiple
            collapse-tags
            filterable
            placeholder=""
            style="width: 100%"
            @change="findByProductKeyList"
          >
            <el-option
              v-for="item in productKeyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:productKeyAdvanceSlot>
          <el-select
            v-model="dataset.advanceFilterConfig.productKey.value"
            multiple
            collapse-tags
            filterable
            placeholder=""
            style="width: 100%"
          >
            <el-option
              v-for="item in productKeyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:deviceStatusSlot="row">
          <div>
            <el-tag
              :type="
                deviceStatusMap[row.datas.deviceStatus] &&
                deviceStatusMap[row.datas.deviceStatus].type
              "
              >{{
                deviceStatusMap[row.datas.deviceStatus] &&
                deviceStatusMap[row.datas.deviceStatus].label
              }}</el-tag
            >
          </div>
          <div style="margin-top: 5px" v-if="row.datas.lastOnlineTime">
            {{ row.datas.lastOnlineTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </template>
        <template v-slot:isolateTimeSlot="row">
          <div v-if="showISOButton(row.datas.isolateEndTime)">
            <el-button size="mini" type="primary" @click="isoHandler(row.datas)"
              >隔离</el-button
            >
          </div>
          <div v-else>
            <el-button
              size="mini"
              type="primary"
              @click="cancelIsoHandler(row.datas)"
              >取消隔离</el-button
            >
          </div>
          <span>
            {{ isoTimeRender(row.datas.isolateEndTime) }}
          </span>
        </template>
      </finalTable>
    </div>

    <!-- 添加设备对话框 -->
    <el-dialog
      :visible.sync="device.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ device.title }}
      </template>
      <el-form
        label-position="top"
        :model="device.form"
        ref="deviceForm"
        :rules="device.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.device.product')" prop="productId">
              <el-select
                :style="{ width: '100%' }"
                filterable
                clearable
                v-model="device.form.productId"
                @change="productChange"
              >
                <el-option
                  v-for="item in productList"
                  :label="item.productName"
                  :value="item.productId"
                  :key="item.productId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.deviceName')"
              prop="deviceName"
            >
              <el-input
                v-model="device.form.deviceName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.deviceDescription')"
              prop="description"
            >
              <el-input
                v-model="device.form.description"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.device.deviceCode')"
              prop="deviceCode"
            >
              <el-input
                v-model="device.form.deviceCode"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('vlink.device.label')" prop="tagList">
          <tag-bind :tagValueList="device.form.tagList" tagType="device" />
        </el-form-item>
      </el-form>
      <div class="dynamic-container" v-if="dynamicJson">
        <div class="form-title">扩展信息</div>
        <DynamicForm :dataset="dynamicJson" ref="dynamicForm" />
      </div>
      <div slot="footer">
        <r-button type="cancel" @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</r-button>
        <r-button plain @click="handleDeviceSave" :loading="submitLoading">{{
          $t("commons.save")
        }}</r-button>
      </div>
    </el-dialog>

    <!-- 保存成功弹框 -->
    <el-dialog
      :visible.sync="successObj.successDialogShow"
      :before-close="
        () => {
          successObj.successDialogShow = false;
        }
      "
      width="728px"
      :close-on-click-modal="false"
    >
      <div class="success-title-line">
        <i class="el-icon-success"></i>
        {{ `成功添加设备 ${successObj.deviceName}，下一步您可以：` }}
      </div>
      <div slot="footer" class="success-footer">
        <el-button type="primary" @click="jumpHanlder('deviceDetail')"
          >查看设备详情</el-button
        >
        <el-button @click="jumpHanlder('deviceNew')">继续添加设备</el-button>
      </div>
    </el-dialog>
    <!-- 关联规则抽屉 -->
    <RuleDrawerComponent
      :show="ruleDrawer.show"
      :datas="ruleDrawer.datas"
      type="device"
      @close="
        ruleDrawer.show = false;
        ruleDrawer.datas = {};
      "
    />
    <!-- 标签 -->
    <el-dialog
      :title="this.tagTitle"
      :visible.sync="tagAddShow"
      width="30%"
      :before-close="cancelTag"
      :close-on-click-modal="false"
      style="height: 600px"
    >
      <!-- 设备标签 -->
      <span v-if="this.tagTitle === '添加标签'">
        <tag-bind :tagValueList="device.form.tagList" tagType="device" />
      </span>
      <div v-loading="tagLoading">
        <span class="tag-container">
          <div class="tag-group" v-if="this.tagTitle === '删除标签'">
            <el-tag
              class="single-tag"
              :style="{
                textDecoration: item.selected ? 'line-through' : 'none',
              }"
              @click="updateTagStatus(item)"
              v-for="item in items"
              :key="item.label"
              :type="item.selected ? 'danger' : 'success'"
            >
              {{ item.label }}
            </el-tag>
          </div>
        </span>
        <div style="margin-top: 20px">
          {{
            `你的操作将对 ${this.device.findMultipleSelection.length} 条数据生效`
          }}
        </div>
      </div>
      <div class="dialog-footer" style="text-align: right; margin: 10px 0">
        <el-button @click="cancelTag">取 消</el-button>
        <el-button type="primary" @click="batchTag">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除设备 -->
    <el-dialog
      title="删除设备"
      :visible.sync="deleteShow"
      width="30%"
      :close-on-click-modal="false"
      :before-close="cancelDelte"
      style="height: 600px"
    >
      <div style="margin-top: 20px">
        {{
          `你的操作将对 ${this.device.findMultipleSelection.length} 条数据生效`
        }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDelte">取 消</el-button>
        <el-button type="primary" @click="batchDelete">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除设备 -->
    <el-dialog
      title="启用设备"
      :visible.sync="enableShow"
      width="30%"
      :close-on-click-modal="false"
      :before-close="cancelEnable"
      style="height: 600px"
    >
      <div style="margin-top: 20px">
        {{
          `你的操作将对 ${this.device.findMultipleSelection.length} 条数据生效`
        }}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEnable">取 消</el-button>
        <el-button type="primary" @click="batchEnable">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 隔离时间 -->
    <el-dialog
      title="隔离时间"
      :visible.sync="timeShow"
      width="30%"
      :before-close="cancelTime"
      :close-on-click-modal="false"
      style="height: 600px"
    >
      <template>
        <el-select v-model="isolateTimeValue" placeholder="请选择">
          <el-option
            v-for="item in isolateTimeSelect"
            :key="item.isolateTimeValue"
            :label="item.isolateTime"
            :value="item.isolateTimeValue"
          >
          </el-option>
        </el-select>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelTime">取 消</el-button>
        <el-button type="primary" @click="batchIsolateTime">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传弹框 -->
    <ImportDialog
      :dataset="importDialog"
      @close="importDialogClose"
      @success="importSuccess"
      @error="importError"
    />
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import DynamicForm from "@/components/DynamicForm";
import RuleDrawerComponent from "@/components/RuleDrawerComponent";
import {
  findDevicePage,
  createDevice,
  deleteDeviceById,
  batchDeleteDevice,
  updateEnableStatus,
  exportDevice,
  batchTagAdd,
  findDeviceTageList,
  batchupdateEnableStatus,
  findDeviceIdList,
  batchTagDelete,
  findDeviceList,
  batchIsolateTime,
  cancelIsolateTime,
  batchEnableDevice,
} from "@/api/ruge/vlink/device/device";
import {
  getProductList,
  findProductDetail,
} from "@/api/ruge/vlink/product/product";
import TagBind from "@/components/TagBind";
import { cloneDeep } from "lodash";
import ImportDialog from "@/components/ImportDialog";
import { envInfo } from "@/constants/envInfo";

export default {
  components: {
    finalTable,
    TagBind,
    RuleDrawerComponent,
    DynamicForm,
    ImportDialog,
  },
  data() {
    return {
      submitLoading: false,
      dynamicJson: null,
      tagLoading: false,
      tagDrop: false,
      startDrop: false,
      deleteDrop: false,
      importDrop: false,
      deviceStatusMap: {
        INACTIVE: {
          type: "info",
          label: "未激活",
        },
        ONLINE: {
          type: "success",
          label: "在线",
        },
        OFFLINE: {
          type: "danger",
          label: "离线",
        },
        OFFLINELONG: {
          type: "danger",
          label: "离线超7天",
        },
      },
      items: [],
      isolateTimeSelect: [
        { isolateTime: this.$t("commons.min30"), isolateTimeValue: "30" },
        { isolateTime: this.$t("commons.min60"), isolateTimeValue: "60" },
        { isolateTime: this.$t("commons.min120"), isolateTimeValue: "120" },
        { isolateTime: this.$t("commons.min180"), isolateTimeValue: "180" },
        { isolateTime: this.$t("commons.min360"), isolateTimeValue: "360" },
        { isolateTime: this.$t("commons.min720"), isolateTimeValue: "720" },
        { isolateTime: this.$t("commons.min1440"), isolateTimeValue: "1440" },
      ],
      isolateTimeValue: "30",
      deleteOptions: [
        {
          deleteValue: "deleteSelected",
          label: "删除设备(本页已选)",
        },
        {
          deleteValue: "deleteAll",
          label: "删除设备(全部)",
        },
      ],
      enableOptions: [
        {
          key: "enableSelected",
          label: "启用(本页已选)",
        },
        {
          key: "enableAll",
          label: "启用(全部)",
        },
        {
          key: "disableSelected",
          label: "禁用(本页已选)",
        },
        {
          key: "disableAll",
          label: "禁用(全部)",
        },
      ],
      deleteValue: "",
      enableValue: "",
      importOptions: [
        {
          key: "extendImport",
          label: "导入扩展信息",
        },
      ],
      tagOptions: [
        {
          tagValue: "addSelected",
          label: "添加标签(本页已选)",
        },
        {
          tagValue: "addAll",
          label: "添加标签(全部)",
        },
        {
          tagValue: "deleteSelected",
          label: "删除标签(本页已选)",
        },
        {
          tagValue: "deleteAll",
          label: "删除标签(全部)",
        },
      ],
      tagValue: "",
      // enableOptions: [
      //   {
      //     enableValue: "enableSelected",
      //     label: "启用(本页已选)",
      //   },
      //   {
      //     enableValue: "enableAll",
      //     label: "启用(全部)",
      //   },
      //   {
      //     enableValue: "disableSelected",
      //     label: "停用(本页已选)",
      //   },
      //   {
      //     enableValue: "disableAll",
      //     label: "停用(全部)",
      //   },
      // ],
      enableValue: "",
      timeOptions: [
        {
          timeValue: "timeSelected",
          label: "隔离时间(本页已选)",
        },
        {
          timeValue: "timeAll",
          label: "隔离时间(全部)",
        },
      ],
      timeValue: "",
      tagTitle: null,
      tagAddShow: false,
      deleteShow: false,
      enableShow: false,
      timeShow: false,
      productKeyOptions: [],
      deviceList: [],
      ruleDrawer: {
        show: false,
        datas: {},
      },
      successObj: {
        successDialogShow: false,
        deviceName: "",
        productKey: "",
      },
      loadingFlag: false,
      productList: [],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          {
            prop: "deviceName",
            label: this.$t("vlink.device.deviceName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.device.deviceDescription"),
            width: "",
          },
          {
            prop: "productKey",
            label: this.$t("vlink.device.product"),
            width: "",
          },
          {
            prop: "parentDeviceName",
            label: this.$t("vlink.device.parentDeviceName"),
            width: "",
          },
          {
            prop: "nodeType",
            label: this.$t("vlink.product.nodeType"),
            width: "",
          },
          {
            prop: "deviceStatus",
            label: this.$t("vlink.device.deviceStatus"),
            width: "",
          },
          {
            prop: "enableStatus",
            label: this.$t("vlink.device.enableStatus"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("vlink.commons.registTime"),
            width: "",
          },
          // {
          //   prop: "activationTime",
          //   label: this.$t("vlink.device.activationTime"),
          //   width: "",
          // },
          {
            prop: "isIsOlate",
            label: "隔离状态",
            width: "100",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "250",
          },
        ],
        tableData: [],
        searchLineConfig: {
          deviceName: {
            type: "input",
            label: "设备标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备标识码",
            prefixIcon: "el-icon-search",
          },
          description: {
            type: "input",
            label: "设备名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入设备名称",
            prefixIcon: "el-icon-search",
          },
          productKey: {
            type: "slot",
            slotName: "productKeySearchSlot",
            value: [],
            label: "所属产品",
          },
          parentDeviceName: {
            type: "selector",
            slotName: "parentDeviceNameSearchSlot",
            placeholder: "请选择节点类型",
            value: "",
            label: "所属网关",
            actionType: "goSearch",
            optionList: [],
          },
          nodeType: {
            type: "selector",
            label: "节点类型",
            value: "",
            placeholder: "请选择节点类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "直连设备",
                value: "DIRECTDEVICE",
              },
              {
                label: "网关设备",
                value: "GATEWAY",
              },
              {
                label: "网关子设备",
                value: "SUBDEVICE",
              },
            ],
          },
          isIsOlate: {
            type: "selector",
            label: "是否隔离",
            value: "",
            placeholder: "请选择是否隔离",
            actionType: "goSearch",
            optionList: [
              {
                label: "已隔离",
                value: "Y",
              },
              {
                label: "未隔离",
                value: "N",
              },
            ],
          },
          deviceStatus: {
            type: "selector",
            label: "设备状态",
            value: "",
            placeholder: "请选择设备状态",
            actionType: "goSearch",
            optionList: [
              {
                label: "未激活",
                value: "INACTIVE",
              },
              {
                label: "在线",
                value: "ONLINE",
              },
              {
                label: "离线",
                value: "OFFLINE",
              },
            ],
          },
          enableStatus: {
            type: "selector",
            label: "启用状态",
            value: "",
            placeholder: "请选择启用状态",
            actionType: "goSearch",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          deviceName: {
            type: "aLink",
            actionType: "aLinkClick",
            targetType: "device",
          },
          productKey: {
            type: "otherParam",
            paramName: "productName",
          },
          nodeType: {
            type: "enumerationColumn",
            emuList: {
              DIRECTDEVICE: "直连设备",
              GATEWAY: "网关设备",
              SUBDEVICE: "网关子设备",
            },
          },
          enableStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          deviceStatus: {
            type: "slot",
            slotName: "deviceStatusSlot",
          },
          creationDate: {
            type: "dateFormat",
          },
          activationTime: {
            type: "dateFormat",
          },
          isIsOlate: {
            type: "slot",
            slotName: "isolateTimeSlot",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "rule",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "关联规则",
              },
              {
                actionType: "iconClick",
                eventName: "alarm",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-document-copy",
                tooltips: "关联告警",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          deviceName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          productKey: {
            type: "slot",
            slotName: "productKeyAdvanceSlot",
            value: [],
            label: "所属产品",
            special: true,
          },
          parentDeviceName: {
            inline: true,
            value: "",
          },
          nodeType: {
            inline: true,
            value: "",
          },
          deviceStatus: {
            inline: true,
            value: "",
          },
          enableStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "注册时间",
            value: [],
          },
          activationTime: {
            type: "dateRange",
            label: "激活时间",
            value: [],
          },
          lastOnlineTime: {
            type: "dateRange",
            label: "最后上线时间",
            value: [],
          },
          isIsOlate: {
            inline: true,
            value: "",
          },
          tagList: {
            type: "tagList",
            label: "标签",
            tagType: "device",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      device: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          deviceName: null,
          deviceStatus: null,
          nodeType: null,
          parentDeviceId: null,
          productKey: "",
          registerStartTime: null,
          registerEndTime: null,
          activeStartTime: null,
          activeEndTime: null,
          lastStartTime: null,
          lastEndTime: null,
        },
        multipleSelection: [],
        findMultipleSelection: [],
        addDialogVisible: false,
        createLoading: false,
        form: {
          deviceName: null,
          productId: null,
          description: null,
          deviceCode: null,
          tagList: [],
          deviceIdList: [],
          enableStatus: null,
          isolateTime: null,
        },
        formRules: {
          productId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          description: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          deviceName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 4,
              message: this.$t("vlink.device.deviceNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-@#()]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
      importDialog: {
        show: false,
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    // 如果从设备详情返回，则需要回显上次的查询条件
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("device_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.device.listQuery = { ...this.device.listQuery, ...tempObj };
        Object.keys(this.device.listQuery).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.device.listQuery[item];
          }
        });
        // 处理所属产品
        if (tempObj.productKey) {
          if (tempObj.productKey instanceof Array) {
            this.dataset.searchLineConfig.productKey.value = tempObj.productKey;
            this.dataset.advanceFilterConfig.productKey.value =
              tempObj.productKey;
          } else {
            this.dataset.searchLineConfig.productKey.value =
              tempObj.productKey.split(",");
            this.dataset.advanceFilterConfig.productKey.value =
              tempObj.productKey.split(",");
          }
        }
        // 处理高级查询中的taglist和创建时间
        const registerTemp = [];
        tempObj.registerStartTime &&
          registerTemp.push(tempObj.registerStartTime);
        tempObj.registerEndTime && registerTemp.push(tempObj.registerEndTime);
        const ActiveTemp = [];
        tempObj.activeStartTime && ActiveTemp.push(tempObj.activeStartTime);
        tempObj.activeEndTime && ActiveTemp.push(tempObj.activeEndTime);
        const lastTemp = [];
        tempObj.lastStartTime || null,
          tempObj.lastEndTime || null,
          tempObj.lastStartTime && lastTemp.push(tempObj.lastStartTime);
        tempObj.lastEndTime && lastTemp.push(tempObj.lastEndTime);
        this.dataset.advanceFilterConfig.creationDate.value = registerTemp;
        this.dataset.advanceFilterConfig.activationTime.value = ActiveTemp;
        this.dataset.advanceFilterConfig.lastOnlineTime.value = lastTemp;
        if (tempObj.tagList && tempObj.tagList.length > 0) {
          this.dataset.advanceFilterConfig.tagList.value =
            tempObj.tagList.split(",");
        }
        // 处理分页数据
        this.dataset.pageVO.current = tempObj.current;
        this.dataset.pageVO.rowCount = tempObj.rowCount;
      }
    } else {
      // 默认查询启用状态数据
      this.dataset.searchLineConfig.enableStatus.value = "ENABLE";
      this.device.listQuery.enableStatus = "ENABLE";
    }
    // console.log("this.$route.params", this.$route.params);
    this.getDevicePage();
    this.getProductList();
    this.getGatewayList();
  },
  mounted() {
    // 从总览跳转过来
    const { type } = this.$route.query;
    if (type === "device" || type === "gateway") {
      this.device.title = this.$t("vlink.device.add");
      this.device.addDialogVisible = true;
      if (type === "gateway") {
        this.$nextTick(() => {
          this.device.form.productId = "GraZGrYZZW8";
        });
      }
    }
  },
  methods: {
    importSuccess() {
      this.importDialogClose();
      this.$message.success("上传成功！");
    },
    importError(error) {
      this.importDialogClose();
      this.$message.warning("上传失败：", error);
    },
    productChange(productId) {
      this.dynamicJson = null;
      if (!productId) return;
      const productKey = this.getProductKeyById(productId);
      findProductDetail({ productKey }).then((res) => {
        try {
          const { json } = JSON.parse(res.extendInfoTemplate);
          this.dynamicJson = json.length ? json : null;
        } catch (error) {}
      });
    },
    getProductKeyById(productId) {
      return this.productList.filter((item) => item.productId === productId)[0]
        .productKey;
    },
    cancelIsoHandler(row) {
      this.$confirm(
        this.$t("message.CancelConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.device.form.deviceId = row.deviceId;
          // 取消隔离时间
          cancelIsolateTime(this.device.form).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.saveSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          this.findDevicePage();
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    isoHandler(row) {
      this.timeShow = true;
      this.device.form.deviceId = row.deviceId;
    },
    isoTimeRender(time) {
      if (!time) return "未隔离";
      const endTime = new Date(time).getTime();
      const currentTime = new Date().getTime();
      if (currentTime >= endTime) return "未隔离";
      return this.dealIosTime(endTime - currentTime);
    },
    showISOButton(time) {
      if (!time) return true;
      const endTime = new Date(time).getTime();
      const currentTime = new Date().getTime();
      return currentTime >= endTime;
    },
    dealIosTime(timeDiff) {
      let date3 = timeDiff; //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      if (
        (days && days > 0) ||
        (hours && hours > 0) ||
        (minutes && minutes > 0) ||
        (seconds && seconds > 0)
      ) {
        return (
          (days > 0 ? days + "天" : "") +
          (hours > 0 ? hours + "小时" : "") +
          (minutes > 0 ? minutes + "分" : "") +
          (seconds > 0 ? seconds + "秒" : "")
        );
      } else {
        return 0 + "分";
      }
    },
    deleteSelect(param) {
      if ("deleteSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.deleteValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("deleteAll" === param) {
        this.findDeviceIdList();
      }
      this.deleteShow = true;
    },
    deleteHandleCommand(command) {
      if ("deleteSelected" === command) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.deleteValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("deleteAll" === command) {
        this.findDeviceIdList();
      }
      this.deleteShow = true;
    },
    enableHandleCommand(command) {
      if (["disableSelected", "enableSelected"].includes(command)) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.enableValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else {
        this.findDeviceIdList();
      }
      this.enableValue = command.includes("enable") ? "ENABLE" : "DISABLE";
      this.enableShow = true;
    },
    tagSelect(param) {
      if ("addSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.tagValue = null;
          return;
        }
        this.tagTitle = "添加标签";
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("addAll" === param) {
        this.tagTitle = "添加标签";
        this.findDeviceIdList();
      } else if ("deleteSelected" === param) {
        this.tagTitle = "删除标签";
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.tagValue = null;
          return;
        }
        this.findDeviceTageList();
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("deleteAll" === param) {
        this.tagTitle = "删除标签";
        this.findDeviceIdList();
        this.findDeviceTageList();
      }
      this.tagAddShow = true;
    },
    tagHandleCommand(param) {
      if ("addSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.tagValue = null;
          return;
        }
        this.tagTitle = "添加标签";
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("addAll" === param) {
        this.tagTitle = "添加标签";
        this.findDeviceIdList();
      } else if ("deleteSelected" === param) {
        this.tagTitle = "删除标签";
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.tagValue = null;
          return;
        }
        this.findDeviceTageList(param);
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("deleteAll" === param) {
        this.tagTitle = "删除标签";
        this.findDeviceIdList();
        this.findDeviceTageList(param);
      }
      this.tagAddShow = true;
    },
    importHandleCommand(param) {
      console.log("param", param);
      switch (param) {
        case "extendImport":
          this.importDialog.datas = {
            title: "导入扩展信息",
            excelType: "ruge.deviceExtendInfo",
            path: envInfo.bgApp.thingsPath,
            rootPath: envInfo.bgApp.thingsPath,
            taskName: "设备扩展信息导入",
          };
          this.importDialog.show = true;
          break;
      }
    },
    importDialogClose() {
      this.importDialog.show = false;
    },
    timeSelect(param) {
      //启用(本页已选) 启用(全部)
      if ("timeSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.timeValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
      } else if ("timeAll" === param) {
        this.findDeviceIdList();
      }
      //启用(本页已选) 启用(全部)
      this.timeShow = true;
    },
    enableSelect(param) {
      //启用(本页已选) 启用(全部) 停用(本页已选) 停用(全部)
      if ("enableSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.enableValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
        this.device.form.enableStatus = "ENABLE";
      } else if ("enableAll" === param) {
        this.device.form.enableStatus = "ENABLE";
        this.findDeviceIdList();
      } else if ("disableSelected" === param) {
        this.device.findMultipleSelection = this.device.multipleSelection;
        if (this.device.findMultipleSelection.length < 1) {
          this.$message.warning("请选择数据！");
          this.enableValue = null;
          return;
        }
        this.device.form.deviceIdList = this.device.findMultipleSelection;
        this.device.form.enableStatus = "DISABLE";
      } else if ("disableAll" === param) {
        this.device.form.enableStatus = "DISABLE";
        this.findDeviceIdList();
      }
      this.batchupdateEnableStatus();
    },
    findDeviceIdList() {
      let param = this.device.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      findDeviceIdList(param)
        .then((res) => {
          // // 成功提示
          // this.$message({
          //   message: this.$t("message.operationSuccess"),
          //   type: "success",
          // });
          this.device.form.deviceIdList = res;
          this.device.findMultipleSelection = res;
        })
        .catch((error) => {
          console.log(`${error}`);
        });
    },
    batchupdateEnableStatus() {
      batchupdateEnableStatus(this.device.form)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`未保存，原因 => ${error}`);
        });
      this.device.form.enableStatus = null;
      this.enableValue = null;
      this.findDevicePage();
    },
    findDeviceTageList(params) {
      if (params == "deleteAll") {
        let param = this.device.listQuery;
        for (let pn in param) {
          !param[pn] && param[pn] !== 0 && (param[pn] = null);
        }
        findDeviceTageList(param)
          .then((res) => {
            let a = res.map((item) => {
              return {
                label: item,
                type: "success",
                selected: false,
              };
            });
            this.items = a;
            this.device.form.tagList = res;
          })
          .catch((error) => {
            console.log(`未保存，原因 => ${error}`);
          });
      } else {
        this.device.form.deviceIdList =
          this.device.findMultipleSelection.join(",");
        findDeviceTageList(this.device.form)
          .then((res) => {
            let a = res.map((item) => {
              return {
                label: item,
                type: "success",
                selected: false,
              };
            });
            this.items = a;
            this.device.form.tagList = res;
          })
          .catch((error) => {
            console.log(`未保存，原因 => ${error}`);
          });
      }
    },
    cancelTag() {
      this.tagAddShow = false;
      this.tagTitle = null;
      this.tagValue = null;
      this.device.form.tagList = [];
      this.device.form.deviceIdList = [];
    },
    cancelDelte() {
      this.deleteShow = false;
      this.deleteValue = null;
      this.device.form.deviceIdList = [];
      this.device.form.tagList = [];
    },
    cancelEnable() {
      this.enableShow = false;
      this.enableValue = null;
      this.device.form.deviceIdList = [];
      this.device.form.tagList = [];
    },
    cancelTime() {
      this.isolateTimeValue = "30";
      this.device.form.isolateTime = null;
      this.timeShow = false;
      this.timeValue = null;
    },
    batchTag() {
      if (this.tagLoading) return;
      this.tagLoading = true;
      if (this.tagTitle === "添加标签") {
        // 等待添加失去焦点的操作
        setTimeout(() => {
          batchTagAdd(this.device.form)
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.saveSuccess"),
                type: "success",
              });
              this.cancelTag();
              this.findDevicePage();
            })
            .catch((error) => {
              console.log(`未保存，原因 => ${error}`);
            })
            .finally(() => {
              this.tagLoading = false;
            });
        }, 200);
      } else {
        this.device.form.tagList = this.items
          .filter((item) => item.selected)
          .map((itme) => itme.label);
        batchTagDelete(this.device.form)
          .then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.saveSuccess"),
              type: "success",
            });
            this.cancelTag();
            this.findDevicePage();
          })
          .catch((error) => {
            console.log(`未保存，原因 => ${error}`);
          })
          .finally(() => {
            this.tagLoading = false;
          });
      }
    },
    updateTagStatus(item) {
      item.selected = !item.selected;
    },
    findByProductKeyList() {
      this.device.listQuery.productKey =
        this.dataset.searchLineConfig.productKey.value.join(",");
      this.dataset.advanceFilterConfig.productKey.value =
        this.dataset.searchLineConfig.productKey.value;
      this.findDevicePage();
    },
    findByGatewayList() {
      this.device.listQuery.parentDeviceId =
        this.dataset.searchLineConfig.parentDeviceName.value;
      this.findDevicePage();
    },
    // 初始化网关设备列表
    getGatewayList() {
      findDeviceList({ nodeType: "GATEWAY" }).then((deviceList) => {
        this.dataset.searchLineConfig.parentDeviceName.optionList =
          deviceList.map((item) => ({
            label: item.deviceName,
            value: item.deviceId,
          }));
      });
    },
    jumpHanlder(type) {
      switch (type) {
        case "deviceDetail":
          this.$router.push({
            path: "/iot/device/detail",
            query: {
              productKey: this.successObj.productKey,
              deviceName: this.successObj.deviceName,
            },
          });
          break;
        case "deviceNew":
          this.successObj.successDialogShow = false;
          this.createDevice();
          break;
      }
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findDevicePage();
    },
    exportHandler() {
      delete this.device.listQuery.activationTime;
      delete this.device.listQuery.creationDate;
      delete this.device.listQuery.lastOnlineTime;
      let params = cloneDeep(this.device.listQuery);
      params.productKey =
        params.productKey instanceof Array
          ? params.productKey.join(",")
          : params.productKey || "";
      if (params.tagList && params.tagList.length) {
        params.tagList = params.tagList.split(",");
      }
      exportDevice(params).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "设备列表",
          taskStatus: 0,
          rootPath: "thingsPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    // 取消保存
    cancelDeviceSave() {
      this.device.addDialogVisible = false;
      this.device.form.extendInfo = null;
      this.dynamicJson = null;
    },
    async handleDeviceSave() {
      // 设备表单必填校验
      await this.$refs.deviceForm.validate();
      // 如果有扩展信息，扩展信息必填校验并获取参数
      if (this.dynamicJson) {
        const extendInfo = await this.$refs.dynamicForm.checkFormValidate();
        this.device.form.extendInfo = JSON.stringify(extendInfo);
      }
      this.submitLoading = true;
      // tagList去重复标签
      this.device.form.tagList = Array.from(new Set(this.device.form.tagList));
      createDevice(this.device.form)
        .then((res) => {
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.cancelDeviceSave();
          this.findDevicePage();
          this.showSuccessDialog(res);
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    showSuccessDialog({ deviceName, productKey }) {
      this.successObj.successDialogShow = true;
      this.successObj.deviceName = deviceName;
      this.successObj.productKey = productKey;
    },
    //初始化产品列表
    getProductList() {
      getProductList()
        .then((res) => {
          this.productList = res.filter(
            (item) => item.nodeType !== "SUBDEVICE"
          );
          this.productKeyOptions = res.map((item) => {
            return {
              value: item.productKey,
              label: item.productName,
            };
          });
          const { type } = this.$route.query;
          if (type === "gateway") {
            this.device.form.productId = 1;
          }
        })
        .finally(() => {
          const { defaultProduct } = this.$route.query;
          this.device.form.productId = defaultProduct;
        });
    },
    // 批量更新隔离时间
    batchIsolateTime() {
      this.device.form.isolateTime = this.isolateTimeValue;
      batchIsolateTime(this.device.form)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.cancelTime();
          this.findDevicePage();
        })
        .catch((error) => {
          this.findDevicePage();
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    // 批量删除
    batchDelete() {
      if (this.device.findMultipleSelection.length < 1) return;
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          batchDeleteDevice({
            deviceIdList: this.device.findMultipleSelection,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          this.cancelDelte();
          console.log(`未删除，原因 => ${error}`);
        });
      this.cancelDelte();
    },
    batchEnable() {
      if (this.device.findMultipleSelection.length < 1) return;
      batchEnableDevice({
        deviceIdList: this.device.findMultipleSelection,
        enableStatus: this.enableValue,
      }).then(() => {
        // 成功提示
        this.$message({
          message: this.$t("message.deleteSuccess"),
          type: "success",
        });
        this.findDevicePage();
      });
      this.cancelEnable();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        // 处理时间参数
        if (datas.params.tagList && datas.params.tagList.length > 0)
          datas.params.tagList = datas.params.tagList.join(",");
        this.dealTime(datas);
        this.dealProductKey(datas);
        // this.findByGatewayList(datas)
        this.device.listQuery.parentDeviceId =
          datas.params.parentDeviceName === undefined
            ? this.device.listQuery.parentDeviceName
            : datas.params.parentDeviceName;
        // delete datas.params.parentDeviceName;
        this.device.listQuery = { ...this.device.listQuery, ...datas.params };
        this.findDevicePage();
      } else if (datas.type === "paginationChange") {
        this.device.listQuery.current = datas.params.current.page;
        this.device.listQuery.rowCount = datas.params.current.limit;
        this.getDevicePage();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list.map((item) => item.deviceId);
      } else if (datas.type === "switchEvent") {
        if (datas.row.deviceStatus === "OFFLINELONG") {
          datas.row.deviceStatus = "OFFLINE";
        }
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.viewDeviceDetail(datas.row);
            break;
          case "delete":
            this.deleteDevice(datas.row.deviceId);
            break;
          case "rule":
            this.ruleDrawerHandler(datas.row);
            break;
          case "alarm":
            this.alarmHandler(datas.row);
            break;
        }
      }
    },
    alarmHandler({ deviceName }) {
      // alarm_list_query_params
      const params = {
        current: 1,
        rowCount: 10,
        deviceName,
      };
      sessionStorage.setItem("alarm_list_query_params", JSON.stringify(params));
      const { origin, pathname } = window.location;
      const targetUrl = `${origin}${pathname}#/iot/alarm/list?deviceName=${deviceName}`;
      window.open(targetUrl, "_blank");
    },
    ruleDrawerHandler(datas) {
      this.ruleDrawer.show = true;
      this.ruleDrawer.datas = datas;
    },
    dealProductKey(datas) {
      if (datas.params.productKey && datas.params.productKey.length) {
        this.device.listQuery.productKey = datas.params.productKey.join(",");
        delete datas.params.productKey;
      }
      return datas;
    },
    dealTime(datas) {
      // 注册时间
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.device.listQuery.registerStartTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.device.listQuery.registerEndTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        if (
          datas.params.creationDate &&
          datas.params.creationDate.length === 0
        ) {
          this.device.listQuery.registerStartTime = "";
          this.device.listQuery.registerEndTime = "";
        }
      }
      // 激活时间
      if (
        datas.params.activationTime &&
        datas.params.activationTime.length > 0
      ) {
        this.device.listQuery.activeStartTime = datas.params.activationTime[0]
          ? new Date(datas.params.activationTime[0]).getTime()
          : null;
        this.device.listQuery.activeEndTime = datas.params.activationTime[1]
          ? new Date(datas.params.activationTime[1]).getTime()
          : null;
        delete datas.params.activationTime;
      } else {
        if (
          datas.params.activationTime &&
          datas.params.activationTime.length === 0
        ) {
          this.device.listQuery.activeStartTime = "";
          this.device.listQuery.activeEndTime = "";
        }
      }
      // 最后上线时间
      if (
        datas.params.lastOnlineTime &&
        datas.params.lastOnlineTime.length > 0
      ) {
        this.device.listQuery.lastStartTime = datas.params.lastOnlineTime[0]
          ? new Date(datas.params.lastOnlineTime[0]).getTime()
          : null;
        this.device.listQuery.lastEndTime = datas.params.lastOnlineTime[1]
          ? new Date(datas.params.lastOnlineTime[1]).getTime()
          : null;
        delete datas.params.lastOnlineTime;
      } else {
        if (
          datas.params.lastOnlineTime &&
          datas.params.lastOnlineTime.length === 0
        ) {
          this.device.listQuery.lastStartTime = "";
          this.device.listQuery.lastEndTime = "";
        }
      }
      return datas;
    },
    createDevice() {
      this.device.title = this.$t("vlink.device.add");
      this.device.form = {
        deviceName: null,
        productId: null,
        deviceCode: null,
        tagList: [],
      };
      this.device.addDialogVisible = true;
    },
    updateEnableStatus(row) {
      updateEnableStatus(row)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
          this.findDevicePage();
        })
        .catch((error) => {
          this.findDevicePage();
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    deleteDevice(deviceId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteDeviceById({ deviceId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findDevicePage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 查看详情
    viewDeviceDetail(row) {
      let query = {
        productKey: row.productKey,
        deviceName: row.deviceName,
      };
      // 如果不是未激活的直接跳转到设备影子页签
      if (row.deviceStatus !== "INACTIVE") {
        query.activeTabName = "shadow";
      }
      this.$router.push({
        path: "/iot/device/detail",
        query,
      });
    },
    findDevicePage() {
      this.device.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getDevicePage();
    },
    getDevicePage() {
      let param = this.device.listQuery;
      // 记录设备列表查询条件
      sessionStorage.setItem("device_list_query_params", JSON.stringify(param));
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.loadingFlag = true;
      findDevicePage(param)
        .then((response) => {
          let resultList = response.rows;
          // this.device.list = response.rows;
          // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
          resultList.forEach((item) => {
            item.deviceStatus = this.getDeviceStatus(
              item.deviceStatus,
              item.parentDeviceStatus
            );
            if (
              item.deviceStatus === "OFFLINE" &&
              new Date().getTime() - new Date(item.lastOnlineTime).getTime() >
                1000 * 60 * 60 * 24 * 7
            ) {
              item.deviceStatus = "OFFLINELONG";
            }
          });
          this.dataset.tableData = resultList;
          this.dataset.pageVO.total = response.total;
          // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 end
          this.loadingFlag = false;
        })
        .catch((error) => {
          console.log("获取设备列表失败：" + error);
          this.loadingFlag = false;
        });
    },
    // XXX 为了实现如果是子设备，网关设备离线，则子设备也显示离线效果，添加此逻辑 begin
    getDeviceStatus(deviceStatus, parentDeviceStatus) {
      // 未激活
      if (deviceStatus == "INACTIVE") {
        return deviceStatus;
      }
      // 如果是子设备，网关设备离线，则子设备也显示离线
      if (parentDeviceStatus && parentDeviceStatus == "OFFLINE") {
        return "OFFLINE";
      } else {
        return deviceStatus;
      }
    },
  },
};
</script>

<style scoped lang="less">
.device-list-comp {
  .tag-container {
    max-height: 400px;
    display: inline-block;
    overflow: auto;
  }
  .dropdown-container {
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    margin-left: 10px;
    padding: 0 10px;
    cursor: pointer;
  }
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-part {
      display: flex;
      align-items: center;
    }
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
    .select-container {
      margin-left: 10px;
    }
  }
  .success-title-line {
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #303133;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .el-icon-success {
      color: #67c23a;
      font-size: 24px;
      margin-right: 15px;
    }
  }
  .success-footer {
    text-align: center;
  }
  .single-tag {
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
  }
  .dynamic-container {
    background: #f5f7f9;
    border-radius: 10px;
    padding: 10px;
    max-height: 300px;
    overflow: auto;
    .form-title {
      font-weight: 600;
      font-size: 16px;
      color: #252d3d;
      margin: 10px 0;
    }
  }
}
</style>